<template>
  <div id="faq" class="min-template">
    <div id="left">
      <div class="wrapper">
        <div class="title">{{ $t("faq.title") }}</div>
        <div class="subtitle">
          {{ $t("faq.subtitle") }}
        </div>
        <div id="left-bottom">
          <div class="subtitle">{{ $t("faq.subtitle2") }}</div>
          <div id="contact-text">{{ $t("faq.contact") }}</div>
          <button class="dotted-btn">
            <a id="call-us" class="text-muted" href="tel:003234581431"
              >+32 3 458 14 31</a
            >
          </button>
          <button class="dotted-btn">
            <a
              href="mailto:hongfukontich@outlook.com?subject=Vraag voor Hong Fu"
              >hongfukontich@outlook.com</a
            >
          </button>
        </div>
      </div>
    </div>
    <div id="right">
      <div class="wrapper">
        <div id="qa-content">
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(1)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[1]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[1]"
                />
              </button>
              <div class="question">{{ $t("faq.q1.q") }}</div>
            </div>
            <div class="answer" v-show="this.toggledItems[1]">
              {{ $t("faq.q1.a") }}
              <div class="disclaimer">
                {{ $t("faq.q1.info") }}
              </div>
            </div>
          </div>
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(2)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[2]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[2]"
                />
              </button>
              <div class="question">{{ $t("faq.q2.q") }}</div>
            </div>
            <div class="answer" v-show="this.toggledItems[2]">
              {{ $t("faq.q2.a") }}
            </div>
          </div>
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(3)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[3]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[3]"
                />
              </button>
              <div class="question">{{ $t("faq.q3.q") }}</div>
            </div>
            <div class="answer" v-show="this.toggledItems[3]">
              {{ $t("faq.q3.a1") }}: 12:00-14:00, 17:00-21:45 <br />
              <b>{{ $t("faq.q3.a2") }}</b
              ><br />
              {{ $t("faq.q3.a3") }}: 12:00-21:45
            </div>
          </div>
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(4)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[4]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[4]"
                />
              </button>
              <div class="question">{{ $t("faq.q4.q") }}</div>
            </div>
            <div class="answer" v-show="this.toggledItems[4]">
              {{ $t("faq.q4.a1") }}&nbsp;<b>{{ $t("faq.q4.a2") }}</b
              >&nbsp;{{ $t("faq.q4.a3") }}
            </div>
          </div>
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(5)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[5]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[5]"
                />
              </button>
              <div class="question">{{ $t("faq.q5.q") }}</div>
            </div>
            <div class="answer" v-show="this.toggledItems[5]">
              {{ $t("faq.q5.a") }}
            </div>
          </div>
          <div class="qa-wrapper">
            <div class="q-wrapper" @click="toggleOpen(6)">
              <button>
                <img
                  class="svg"
                  src="../assets/plus.svg"
                  v-show="!this.toggledItems[6]"
                />
                <img
                  class="svg"
                  src="../assets/minus.svg"
                  v-show="this.toggledItems[6]"
                />
              </button>
              <div class="question">
                {{ $t("faq.q6.q") }}
              </div>
            </div>
            <div class="answer" v-show="this.toggledItems[6]">
              {{ $t("faq.q6.a") }}
              <div class="disclaimer">
                {{ $t("faq.q6.info") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Faq",
  components: {},
  data() {
    return {
      toggledItems: [false, false, false, false, false, false],
    };
  },
  methods: {
    toggleOpen(i) {
      this.toggledItems[i] = !this.toggledItems[i];
    },
  },
};
</script>

<style scoped>
.min-template {
  min-height: calc(100vh - 165px);
}
.wrapper {
  width: 80%;
  margin: 0 auto;
}
.svg {
  width: 15px;
}
.disclaimer {
  font-family: "SourceSansPro Bold";
  font-size: 14px;
}
.dotted-btn {
  border: 2px dotted rgb(0, 0, 0);
  padding: 15px;
  margin-right: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.dotted-btn a {
  color: #2c3e50;
}
.dotted-btn:hover {
  text-decoration: underline;
  color: rgb(157, 66, 71);
}

#left,
#right {
  height: 100%;
  float: left;
  padding-top: 55px;
  text-align: left;
}
#left {
  width: 40%;
}
#left .wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
}
#left ul {
  text-align: left;
  list-style-type: none;
}
#left-bottom {
  margin-top: auto;
  padding-bottom: 45px;
}
#left-bottom .subtitle {
  margin-bottom: 5px;
}
#left-bottom #contact-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
}
#right {
  width: 60%;
}
#right #qa-content {
  margin-bottom: 45px;
}
#right #qa-content .qa-wrapper {
  border-bottom: 2px solid rgba(41, 31, 37, 0.15);
  padding-bottom: 20px;
  margin-bottom: 25px;
  font-size: 20px;
}
#right #qa-content .qa-wrapper:last-child {
  border-bottom: unset;
}
#right #qa-content .qa-wrapper .q-wrapper,
.q-wrapper button {
  display: flex;
  align-items: center;
}
#right #qa-content .qa-wrapper .q-wrapper:hover {
  cursor: pointer;
}
#right #qa-content .question {
  font-weight: bold;
  padding: 5px 5px 5px 20px;
}
#right #qa-content .answer {
  padding: 5px 5px 5px 35px;
}

@media only screen and (max-width: 600px) {
  #left,
  #right {
    width: 100%;
    float: unset;
  }
  #left {
    padding-top: 35px;
  }
  #right {
    padding-top: unset;
  }
  #right .wrapper {
    width: 90%;
  }
}
</style>
